<template>
  <div>
    <div class="content  adjunction">
      <span>编辑/添加图片</span>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="10">
          <el-form
            label-position="top"
            label-width="80px"
            :model="form"
            hide-required-asterisk
            ref="form"
            :rules="rules"
          >
            <el-form-item prop="customerId" label="客户名称">
              <el-select
                style="width: 100%"
                v-model="form.customerId"
                placeholder="请选择"
                :disabled="!!Object.keys(this.editDatas).length"
              >
                <el-option
                  v-for="item in customer"
                  :key="item.customerId"
                  :label="item.name"
                  :value="item.customerId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="featureId" label="资产型号">
              <el-select
                clearable
                filterable
                style="width: 100%;"
                v-model="form.featureId"
                :disabled="!!Object.keys(this.editDatas).length"
                placeholder="请选择资产型号"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="添加图片">
              <el-upload
                ref="upload"
                class="avatar-uploader"
                action=""
                :file-list="fileList"
                :auto-upload="false"
                list-type="picture-card"
                :on-change="handleChange"
                :on-remove="handleRemove"
                :show-file-list="true"
              >
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item prop="controlStatusList" label="设备可控状态">
              <el-checkbox-group v-model="form.controlStatusList">
                <el-checkbox
                  v-for="item in dict.controlStatusList"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-button class="button_cancel input" @click="cancel">取消</el-button>
    <el-button
      class="button_preserve"
      @click="submitUpload"
      :loading="loading.save"
      >保存</el-button
    >
  </div>
</template>

<script>
import {
  clientName,
  editImg,
  getAssetType
} from "../../../api/AssetManagement";
export default {
  name: "imgAdd",
  data() {
    return {
      fileList: [],
      isImage: true,
      id: "",
      url: window.baseUrl + "/clodchainAM/web/add_customer_feature_image",
      uploadUrl: window.baseUrl + "/clodchainAM/web/add_customer_feature_image",
      form: {
        featureId: "",
        customerId: "",
        controlStatusList: []
      },
      token: localStorage.getItem("token"),
      userId: localStorage.getItem("userId"),
      options: [],
      customer: [],
      imgId: "",
      editDatas: {},
      dict: {
        controlStatusList: this.$store.state.controlStatusList
      },
      rules: {
        customerId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: "change"
          }
        ],
        featureId: [
          {
            required: true,
            message: "请选择资产型号",
            trigger: "change"
          }
        ],
        controlStatusList: [
          {
            required: true,
            message: "请选择设备可控状态",
            trigger: "change"
          }
        ]
      },
      loading: {
        save: false
      }
    };
  },
  created() {
    this.editDatas = this.$route.query || {};
    this.getAssetTypes();
    this.getClient();
    if (Object.keys(this.editDatas).length != 0) {
      this.form.controlStatusList = this.editDatas.controlStatusList;
      this.form.customerId = parseInt(this.editDatas.customerId);
      this.imgId = this.editDatas.imgId;
      const arr = this.editDatas.imageUrl.split("/");
      if (this.editDatas.imageUrl)
        this.fileList = [
          {
            name: arr[arr.length - 1],
            url: this.editDatas.imageUrl
          }
        ];
    }
  },
  methods: {
    //获取资产类型
    getAssetTypes() {
      getAssetType({}).then(res => {
        this.options = res.data.data;
        if (Object.keys(this.editDatas).length !== 0) {
          const item = this.options.find(
            el => el.name === this.editDatas.deviceFeature
          );
          if (item) this.form.featureId = item.id;
        }
      });
    },
    //取消
    cancel() {
      this.$router.push("/dashboard/client/imgManage");
    },
    //上传图片
    submitUpload() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading.save = true;
          const formdata = new FormData();
          if (this.fileList[0]) {
            if (!this.fileList[0].raw && !this.fileList[0].url) {
              formdata.append("isModified", 1);
            }
          } else {
            formdata.append("isModified", 1);
          }
          formdata.append("token", localStorage.getItem("token"));
          formdata.append("userId", localStorage.getItem("userId"));
          formdata.append("imageId", this.editDatas.imageId || "");
          formdata.append("featureId", this.form.featureId);
          formdata.append("controlStatusList", this.form.controlStatusList);
          formdata.append(
            "file",
            this.fileList[0] ? this.fileList[0].raw || this.fileList[0].url : ""
          );
          formdata.append("customerId", this.form.customerId);
          const config = {
            data: formdata
          };
          editImg(config).then(res => {
            this.loading.save = false;
            if (res.data.desc == "SUCCESS") {
              this.$emit("confirm");
              this.$message({
                showClose: true,
                message: "保存成功！",
                type: "success"
              });
              this.$router.push({ path: "/dashboard/client/imgManage" });
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorDesc || "保存失败！",
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleChange(file, fileList) {
      if (file.status === "ready") {
        const isJPG = file.raw.type === "image/jpeg";
        const isLt2M = file.raw.size / 1024 / 1024 < 2;
        if (!isJPG) {
          fileList.pop();
          this.$message({
            duration: 1000,
            showClose: true,
            message: "上传头像图片只能是 JPG 格式!",
            type: "error"
          });
          return;
        }
        if (!isLt2M) {
          fileList.pop();
          this.$message({
            duration: 1000,
            showClose: true,
            message: "上传头像图片大小不能超过 2MB!",
            type: "error"
          });
          return;
        }
      }
      this.fileList = fileList.splice(-1);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList.splice(-1);
    },
    //拉去客户名称
    getClient() {
      clientName({}).then(res => {
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          this.customer.push({
            customerId: data[i].id,
            name: data[i].name
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.input {
  margin-top: 20px;
}
.adjunction {
  background: #ffffff;
  border: 1px solid #e8eaf0;
  border-radius: 3px;
  border-radius: 3px;
}
/* 多选框样式 */
/deep/ .el-checkbox {
  display: block;
  width: 30px;
  height: 25px;
}
/deep/ .Code_item .el-checkbox:last-of-type {
  margin-left: 30px;
}
/deep/ el-form-item__label {
  color: #333;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: -0.04px;
  line-height: 16px;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}

.content {
  width: 97%;
  /* height: 700px; */
  background: #fff;
  padding: 30px 30px;
}

/deep/ .el-divider--horizontal {
  margin: 15px 0 !important;
}
</style>
